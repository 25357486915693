





































import { ciFilterCategoriesV2 } from '~/icons/source/regular/filter-categories-v2'
import { Size } from '~/models/app/size'
import Vue, { PropType } from 'vue'

export default Vue.extend({
  props: {
    iconOnly: { type: Boolean, default: false },
    rightSideCoutner: { type: Boolean, default: false },
    facetsCount: { type: Number, required: true },
    withoutLabel: { type: Boolean, default: false },
    size: {
      type: String as PropType<Size>,
      default: Size.SM
    }
  },
  computed: {
    icons() {
      return {
        filterIcon: ciFilterCategoriesV2
      }
    }
  }
})
